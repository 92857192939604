import { createStore } from 'vuex';
import axios from 'axios';
import callAPI from '../axios-api';
import router from '@/router';

interface CartState {
  ticket: any;
  quantity: number;
  cart: {
    items: Array<any>; // You can replace 'any' with a more specific type
  };
}

interface VTIXAuth {
  isAuthenticated: boolean;
  token: string;
  user_id: string;
  profileType: string;
  refresh_token: string;
  merchant_slug: string;
}

export default createStore({
  state: {
    cart: {
      items: [] as CartState[],
    },
    isAuthenticated: false,
    token: '',
    user_id: '',
    profileType: '',
    isLoading: false,
    refresh_token: '',
    merchant_slug: '',
    baseURL: process.env.VUE_APP_API_URL,
  },
  getters: {
    cartTotal(state) {
      let total = 0;
      state.cart.items.forEach((item: { quantity: number }) => {
        total += item.quantity;
      });
      return total;
    },
    loadState(state) {
      return state.isLoading;
    },
    getRefreshToken(state) {
      return state.refresh_token;
    },

    isLoggedIn(state) {
      return state.token !== '';
    },
    getToken(state) {
      return state.token;
    },
    getUserId(state) {
      return state.user_id;
    },
    isProfiletype(state) {
      return state.profileType;
    },
    merchantSlug(state) {
      return state.merchant_slug;
    },
  },
  mutations: {
    initializeStore(state) {
      if (localStorage.getItem('cart')) {
        state.cart = JSON.parse(localStorage.getItem('cart') as string);
      } else {
        localStorage.setItem('cart', JSON.stringify(state.cart));
      }

      if (localStorage.getItem('token')) {
        state.token = localStorage.getItem('token') as string;
        state.user_id = localStorage.getItem('user_id') as string;
        state.profileType = localStorage.getItem('profileType') as string;
        state.refresh_token = localStorage.getItem('refresh_token') as string;
        state.merchant_slug = localStorage.getItem('merchant_slug') as string;
        state.isAuthenticated = true;
      } else {
        localStorage.setItem('token', '');
        localStorage.setItem('user_id', '');
        localStorage.setItem('profileType', '');
        localStorage.setItem('refresh_token', '');
        localStorage.setItem('merchant_slug', '');
        state.isAuthenticated = false;
      }
    },
    createToken(state, { token, refresh_token, user_id }) {
      state.token = token;
      state.user_id = user_id;
      state.refresh_token = refresh_token;

      localStorage.setItem('token', token);
      localStorage.setItem('user_id', user_id);
      localStorage.setItem('refresh_token', refresh_token);
      state.isAuthenticated = true;
    },

    //Items contains an array of ticket objects that contain the event id, the ticket id, and the quantity
    addToCart(state, item) {
      let exists = JSON.parse(JSON.stringify(state.cart.items));
      if (exists.length > 0) {
        const itemArray = JSON.parse(JSON.stringify(item));

        for (let i = 0; i < itemArray.length; i++) {
          const objToUpdate = exists.findIndex(
            (obj: { id: any }) => obj.id === itemArray[i].id
          );

          if (objToUpdate >= 0) {
            state.cart.items[objToUpdate].quantity =
              state.cart.items[objToUpdate].quantity +
              parseInt(itemArray[i].quantity);
            //state.cart.items.splice(objToUpdate, 1);
            //state.cart.items = exists;

            //return;
          } else {
            state.cart.items.push(item[i]);
          }
        }
        //localStorage.setItem('cart', JSON.stringify(state.cart));
        //state.cart.items.push(item[0]);
      } else {
        item.forEach((element: any) => {
          state.cart.items.push(element);
        });

        // state.cart.items.push(item[0]);
      }
      localStorage.setItem('cart', JSON.stringify(state.cart));
      /* 
      }); */

      /*     const exists = state.cart.items.filter(
        (i) => i.ticket.id === item.ticket.id
      ); */
      /*  if (exists.length) {
        exists[0].quantity =
          parseInt(exists[0].ticket.quantity) + parseInt(item.quantity);
      } else {
        state.cart.items.push(item);
      }
      localStorage.setItem('cart', JSON.stringify(state.cart)); */
    },
    clearCart(state) {
      state.cart.items = [];
      localStorage.setItem('cart', JSON.stringify(state.cart));
    },

    removeFromCart(state, item) {
      const index = state.cart.items.indexOf(item);
      state.cart.items.splice(index, 1);
      localStorage.setItem('cart', JSON.stringify(state.cart));
    },

    setToken(state, { token, refresh_token, user_id }) {
      state.token = token;
      state.user_id = user_id;
      state.refresh_token = refresh_token;
      localStorage.setItem('token', token);
      localStorage.setItem('user_id', user_id);
      localStorage.setItem('refresh_token', refresh_token);
      state.isAuthenticated = true;
    },

    updateToken(state, { token, refresh_token }) {
      state.token = token;

      state.refresh_token = refresh_token;
      localStorage.setItem('token', token);
      localStorage.setItem('refresh_token', refresh_token);
      state.isAuthenticated = true;
    },

    clearToken(state) {
      state.token = '';
      state.user_id = '';
      state.profileType = '';
      state.refresh_token = '';
      state.merchant_slug = '';
      localStorage.setItem('token', '');
      localStorage.setItem('user_id', '');
      localStorage.setItem('profileType', '');

      localStorage.setItem('refresh_token', '');
      localStorage.setItem('merchant_slug', '');
      state.isAuthenticated = false;
    },
    setProfileType(state, profileType) {
      state.profileType = profileType;
      localStorage.setItem('profileType', profileType);
    },
    setMerchantSlug(state, slug) {
      state.merchant_slug = slug;
      localStorage.setItem('merchant_slug', slug);
    },
  },
  actions: {
    userLogin({ commit }, { username, password }) {
      this.state.isLoading = true;

      return new Promise(async (resolve, rejects) => {
        await callAPI
          .post('/api/v1/account/signin/', {
            username: username,
            password: password,
          })
          .then((response) => {
            this.state.isLoading = false;

            if (response.status == 200) {
              const token = response.data.access;
              const refreshToken = response.data.refresh;
              const userId = response.data.user.id;

              //this.state.profileType = response.data.profile.profile_type;
              commit('setToken', {
                token: token,
                refresh_token: refreshToken,
                user_id: userId,
              });
              resolve(response);
            } else {
              this.state.isLoading = false;
              rejects(response);
            }
          })
          .catch((error) => {
            this.state.isLoading = false;
            rejects(error.response);
          });
      });
    },
    userReg(
      {},
      {
        email,
        password,
        first_name,
        last_name,
        birthday,
        phone,
        address,
        city,
        profile_type,
      }
    ) {
      return new Promise((resolve, rejects) => {
        this.state.isLoading = true;
        callAPI
          .post('/api/v1/account/signup/', {
            user: {
              email: email,
              username: email,
              password: password,
              first_name: first_name,
              last_name: last_name,

              is_active: true,
            },
            profile_type: profile_type,
            city: city,
            address: address,
            country: '',
            phone: phone,
            birthday: birthday,
            race: '',
            sex: '',
          })
          .then((response) => {
            this.state.isLoading = false;
            if (response.status == 201 || response.status == 200) {
              /*      const token = response.data.token;
              const refreshToken = response.data.refresh;
              const userId = response.data.user.id;

              commit('setToken', {
                token: token,
                refresh_token: refreshToken,
                user_id: userId,
              }); */
              this.state.isLoading = false;
              resolve(response);
            } else {
              this.state.isLoading = false;
              rejects(response.data.detail);
            }
          })
          .catch((error) => {
            this.state.isLoading = false;
            rejects(error.response.data.detail);
          });
      });
    },
    confirmReset({ commit }, { password, user_id, timestamp, signature }) {
      return new Promise((resolve, rejects) => {
        this.state.isLoading = true;
        callAPI
          .post('/api/v1/account/reset-password/', {
            password: password,
            user_id: user_id,
            timestamp: timestamp,
            signature: signature,
          })
          .then((response) => {
            this.state.isLoading = false;
            if (response.status == 200) {
              this.state.isLoading = false;
              resolve(response);
            } else {
              this.state.isLoading = false;
              rejects(response);
            }
          })
          .catch((error) => {
            this.state.isLoading = false;
            rejects(error);
          });
      });
    },
    reAuthorizeApi({ commit }, refresh_token) {
      return new Promise((resolve, rejects) => {
        this.state.isLoading = true;
        callAPI
          .post('/api/v1/jwt/refresh/', {
            refresh: refresh_token,
          })
          .then((response) => {
            if (response.data.access) {
              const token = response.data.access;
              const refreshToken = response.data.refresh;

              commit('updateToken', {
                token: token,
                refresh_token: refreshToken,
              });
              resolve(response);
              this.state.isLoading = false;
            } else {
              rejects(response);
              this.state.token = '';
              this.state.refresh_token = '';
              this.state.user_id = '';
              this.state.profileType = '';
              this.state.isAuthenticated = false;
              localStorage.setItem('token', '');
              localStorage.setItem('refresh_token', '');
              localStorage.setItem('user_id', '');
              localStorage.setItem('profileType', '');

              this.state.isLoading = false;
              router.push({ name: 'Login' });
            }
          })
          .catch((error) => {
            rejects(error);
            this.state.isLoading = false;
            this.state.token = '';
            this.state.refresh_token = '';
            this.state.user_id = '';
            this.state.profileType = '';
            this.state.isAuthenticated = false;
            localStorage.setItem('token', '');
            localStorage.setItem('refresh_token', '');
            localStorage.setItem('user_id', '');
            localStorage.setItem('profileType', '');
          });
      });
    },
  },
  modules: {},
});
