<template>
  <div
    id="overlay"
    :class="this.$store.getters.loadState === true ? '' : 'hide'"
  >
    <div
      class="spinner"
      :class="this.$store.getters.loadState === true ? '' : 'hide'"
    ></div>
    <br />
    Processing....
  </div>
</template>
<script>
export default {
  name: 'Loader',

  data() {
    return {
      show: false,
    };
  },
  watch: {
    //watch for changes in the show prop
    show() {
      this.show = this.$store.getters.loadState;
    },
  },
  mounted() {
    //set the show prop to the value of the isLoading state
    this.show = this.$store.getters.loadState;
  },
  methods: {
    showLoader() {
      $('#overlay').fadeIn().delay(2000);
      $('div.spanner').addClass('show');
      $('div.overlay').addClass('show');
    },
    hideLoader() {
      $('div.spanner').removeClass('show');
      $('div.overlay').removeClass('show');
    },
  },
};
</script>
<style scoped>
.hide {
  display: none;
}
#overlay {
  background: #ffffff;
  color: #666666;
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 5000;
  top: 0;
  left: 0;
  float: left;
  text-align: center;
  padding-top: 15%;
  opacity: 0.8;
}
button {
  margin: 40px;
  padding: 5px 20px;
  cursor: pointer;
}
.spinner {
  margin: 0 auto;
  height: 64px;
  width: 64px;
  animation: rotate 0.8s infinite linear;
  border: 5px solid firebrick;
  border-right-color: transparent;
  border-radius: 50%;
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
