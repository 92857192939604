import axios from 'axios';

const callAPI = axios.create({
  baseURL: process.env.VUE_APP_BACKEND_URL,
  //timeout: 1000, // Use 'timeout' instead of 'setTimeout'
  headers: {
    'Content-Type': 'application/json',
  },
});

const AuthAPI = axios.create({
  baseURL: process.env.VUE_APP_BACKEND_URL,
  //timeout: 1000, // Use 'timeout' instead of 'setTimeout'
  headers: {
    'Content-Type': 'application/json',
    Authorization: `JWT ${localStorage.getItem('token')}`,
  },
});

export default callAPI;
export { AuthAPI };
